/** @jsx jsx */
import { jsx } from "theme-ui"
import React from 'react'

function Highlight (props) {
  return (
    <span className='highlight'>
      This is a highlight
    </span>
  )
}

export default Highlight;
